import { useState, useEffect, SetStateAction } from "react";
import { getApiUrl } from "../utils/isDev";
import { ITActivity } from "./UseFetchActivities.hook";

interface HolderInfo {
  address: string;
  balance: number;
}

export interface ITokenStatistics {
  topHolder: HolderInfo;
  leastHolder: HolderInfo;
  totalHolders: number;
  averageHolding: number;
  event:ITActivity|null;
}

export function useFetchStats(token: string) {
  const [stats, setStats] = useState<ITokenStatistics>({
    topHolder: {
      address: "",
      balance: 0,
    },
    leastHolder: {
      address: "",
      balance: 0,
    },
    totalHolders: 0,
    averageHolding: 0,
    event:null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function fetchTokens() {
      try {
        const response = await fetch(
          `${getApiUrl()}/api/token/token-stats?tokenId=${token}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const tokenStats = await response.json();
        setStats(tokenStats);
      } catch (e) {
        setError(e as SetStateAction<null>);
      } finally {
        setLoading(false);
      }
    }

    fetchTokens();
  }, [token]);

  return { stats, loading, error };
}
