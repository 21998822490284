import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetchStats } from "../../hooks/useFetchStats.hook";
import StatsHoldersComponent from "../../components/StatsHolders/StatsHolders.component";

const TokenStats = ({ currentToken }: { currentToken: string }) => {
  const { stats, loading, error } = useFetchStats(currentToken);
  const navigator=useNavigate()
  const theme = useTheme();
  const secondaryTextColor = theme.palette.secondary.main;
  if (loading)
    return (
      <Grid item xs={12} md={6}>
        <Skeleton sx={{ fontSize: "30px" }} variant="text" />
        <Skeleton sx={{ fontSize: "30px" }} variant="text" />
        <Skeleton sx={{ fontSize: "30px" }} variant="text" />
        <Skeleton sx={{ fontSize: "30px" }} variant="text" />
      </Grid>
    );
  if (error) return <Typography>Error loading data!</Typography>;
  if (!stats || !(stats as any).token) {
    // page with no stats
    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: "rgba(11,8,1,1)",
          minHeight: "120px",
          borderRadius: "10px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            borderBottom: "1px solid rgba(255,255,255,0.2)",
            height: "50px",
          }}
        >
          <Typography variant="h6">Overview</Typography>
          <Box m={1} />
          <Typography
            variant="h5"
            style={{
              color: secondaryTextColor,
            }}
          >
            No Stats Available for this Token
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        // width: "100%",
        backgroundColor: "rgba(11,8,1,1)",
        minHeight: "120px",
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid rgba(255,255,255,0.2)",
          height: "50px",
        }}
      >
        <Box display={"flex"}>
          {" "}
          <Typography variant="h6">Overview</Typography>
          <Box m={1} />
          <Typography
            variant="h5"
            style={{
              color: secondaryTextColor,
            }}
          >
            {(stats as any).token.token}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigator("/token/" + currentToken)}
          >
            Mint
          </Button>
          <Box m={1} />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigator("/marketplace/" + currentToken)}
          >
            Marketplace
          </Button>
        </Box>
      </Box>
      <Box m={1} />
      {
        <Grid container spacing={1}>
          {Object.keys((stats as any).token)
            .filter((i) => i !== "action" && i !== "type")
            .map((key: any) => (
              <Grid item xs={12} lg={12} key={key}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    style={{
                      color: "rgba(255,255,255,0.5)",
                    }}
                  >
                    {key == "balance" ? "Minted" : key}
                  </Typography>
                  <Typography variant="body1">
                    {(stats as any).token[key]}
                  </Typography>
                </Box>
              </Grid>
            ))}
          <Grid item xs={12} lg={12}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                style={{
                  color: "rgba(255,255,255,0.5)",
                }}
              >
                Total Holders
              </Typography>
              <Typography variant="body1">{stats.totalHolders}</Typography>
            </Box>
          </Grid>
        </Grid>
      }
    </Box>
  );

  // return (
  //   <Grid
  //     item
  //     xs={12}
  //     md={12}
  //     sx={{
  //       display: "flex",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <StatDisplay label="Total Holders" value={stats.totalHolders} />
  //     <StatDisplay
  //       label="Average Holding"
  //       value={stats.averageHolding.toFixed(2)}
  //     />
  //     {stats.topHolder && (
  //       <StatDisplay
  //         label="Top Holder"
  //         value={`${shortenString(stats.topHolder.address)} (${
  //           stats.topHolder.balance
  //         })`}
  //       />
  //     )}
  //     {stats.leastHolder && (
  //       <StatDisplay
  //         label="Least Holder"
  //         value={`${shortenString(stats.leastHolder.address)} (${
  //           stats.leastHolder.balance
  //         })`}
  //       />
  //     )}
  //   </Grid>
  // );
};

const StatsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const { token } = useParams<{ token: string }>();

  // alert(token);

  const [address, setAddress] = useState("");
  const [found, setFound] = useState();

  const handleSearch = () => {
    // const result = rankedData.find((item) => item.address === address);
    // setFound(result as any);
  };

  return (
    // <Container maxWidth="md">
    <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
      <TokenStats currentToken={token as string} />
      <Box m={2} />
      <StatsHoldersComponent tokenId={token as string} />
    </Box>
    // </Container>
  );
};

export default StatsPage;
